.product {
  position: relative;
  top: 5em;
  z-index: 9;
  padding: 1em 3em;
}

.loading {
  height: 100vh;
  display: grid;
  place-items: center;
}

.productInfo {
  margin-top: 2em !important;
}

.details p {
  font-size: 1.2em;
}

@media only screen and (max-width: 600px) {
  .product {
    padding: 1em 1em 2em;
  }

  .productInfo {
    margin-top: 0.5em !important;
  }

  .buy_btn {
    width: 100%;
  }
}
