.about {
  position: relative;
  top: 5em;
  z-index: 9;
  padding: 1em 10em;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.about h2 {
  font-size: 2.5em;
}

.about p {
  font-size: 1.2em;
}

@media only screen and (max-width: 600px) {
  .about {
    padding: 1em;
  }

  .about h2 {
    font-size: 2em;
  }

  .about p {
    font-size: 1em;
  }
}
