@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Megrim&display=swap);
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Montserrat", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

.navbar {
  position: fixed;
  z-index: 10;
  width: 100%;
  padding: 1em 2em;
  border-bottom: 1px solid #ebe8e8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fafafa;
}

#logo {
  font-family: "Megrim", cursive;
  font-size: 2em;
  letter-spacing: 2px;
  color: #7868e6;
}

.navbar ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar ul li {
  color: #1e1e1e;
  font-size: 1.3em;
  cursor: pointer;
  list-style: none;
  margin: 0 1em;
}

.navbar ul li:hover {
  color: #7868e6;
}

@media only screen and (max-width: 600px) {
  #logo {
    font-size: 1.5em;
  }

  .navbar ul li {
    font-size: 1em;
  }
}

.loading {
  height: 100vh;
  display: grid;
  place-items: center;
}

.home {
  position: relative;
  top: 5em;
  z-index: 9;
  padding: 1em 3em;
}

.trending .card .image {
  margin: 2em auto;
  width: 10em;
}

.trending .card .content {
  display: flex;
  flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
}

.hidden {
  align-items: center;
}

#productTitle {
  color: #1e1e1e;
  text-decoration: none;
}

.card {
  margin: 1.5em 3em !important;
}

/* .trending .card {
  transition: all ease-in-out 1s;
}

.trending .card:hover {
  transform: translateY(-10px);
} */

@media only screen and (max-width: 600px) {
  .home {
    padding: 1em;
  }

  .card {
    margin: 1em !important;
  }
}

.about {
  position: relative;
  top: 5em;
  z-index: 9;
  padding: 1em 10em;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.about h2 {
  font-size: 2.5em;
}

.about p {
  font-size: 1.2em;
}

@media only screen and (max-width: 600px) {
  .about {
    padding: 1em;
  }

  .about h2 {
    font-size: 2em;
  }

  .about p {
    font-size: 1em;
  }
}

.cart {
  position: relative;
  top: 5em;
  z-index: 9;
  padding: 1em 5em;
  margin-bottom: 3em !important;
}

p {
  font-size: 1.2em;
}

.cartItem {
  padding: 2em 0 !important;
}

.cartContent {
  padding-left: 3em !important;
}

.checkout {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: 2em;
}

.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.empty Button {
  margin-top: 1em !important;
}

@media only screen and (max-width: 600px) {
  .cart {
    padding: 1em;
  }

  .cartItem {
    display: flex !important;
    flex-direction: column;
    padding: 2em 1em !important;
  }

  .cartContent {
    padding: 1em 0 !important;
  }

  .checkout {
    padding: 1em;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.product {
  position: relative;
  top: 5em;
  z-index: 9;
  padding: 1em 3em;
}

.loading {
  height: 100vh;
  display: grid;
  place-items: center;
}

.productInfo {
  margin-top: 2em !important;
}

.details p {
  font-size: 1.2em;
}

@media only screen and (max-width: 600px) {
  .product {
    padding: 1em 1em 2em;
  }

  .productInfo {
    margin-top: 0.5em !important;
  }

  .buy_btn {
    width: 100%;
  }
}

