.loading {
  height: 100vh;
  display: grid;
  place-items: center;
}

.home {
  position: relative;
  top: 5em;
  z-index: 9;
  padding: 1em 3em;
}

.trending .card .image {
  margin: 2em auto;
  width: 10em;
}

.trending .card .content {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.hidden {
  align-items: center;
}

#productTitle {
  color: #1e1e1e;
  text-decoration: none;
}

.card {
  margin: 1.5em 3em !important;
}

/* .trending .card {
  transition: all ease-in-out 1s;
}

.trending .card:hover {
  transform: translateY(-10px);
} */

@media only screen and (max-width: 600px) {
  .home {
    padding: 1em;
  }

  .card {
    margin: 1em !important;
  }
}
