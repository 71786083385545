@import url("https://fonts.googleapis.com/css2?family=Megrim&display=swap");

.navbar {
  position: fixed;
  z-index: 10;
  width: 100%;
  padding: 1em 2em;
  border-bottom: 1px solid #ebe8e8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fafafa;
}

#logo {
  font-family: "Megrim", cursive;
  font-size: 2em;
  letter-spacing: 2px;
  color: #7868e6;
}

.navbar ul {
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbar ul li {
  color: #1e1e1e;
  font-size: 1.3em;
  cursor: pointer;
  list-style: none;
  margin: 0 1em;
}

.navbar ul li:hover {
  color: #7868e6;
}

@media only screen and (max-width: 600px) {
  #logo {
    font-size: 1.5em;
  }

  .navbar ul li {
    font-size: 1em;
  }
}
